import {REDUX_CONSTANTS} from "utilities/constant";

const initialLoginState = {
    value: 0,
    mobile: false,
    toggled: false
}

const sidebarWithReducer = (state = initialLoginState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case REDUX_CONSTANTS.CHANGE_SIDE_BAR_WITH:
            return {
                ...state,
                ...payload
            }
        case REDUX_CONSTANTS.CHANGE_SIDE_BAR_STATE:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default sidebarWithReducer
