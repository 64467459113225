import {REDUX_CONSTANTS} from "utilities/constant";

const {REGISTER, REGISTERING_STATE} = REDUX_CONSTANTS;

const initialRegisterState = {
    loggedInUser: {
       // cart: []
    },
    state: null,
    isLoggedIn: false
}

const registerReducer = (state = initialRegisterState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case REGISTER:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    ...payload.user
                },
                isLoggedIn: payload.isLoggedIn
            }
        case REGISTERING_STATE: {
            return {
                ...state,
                state: payload
            }
        }
        default:
            return state
    }
}

export default registerReducer