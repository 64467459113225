import axios from 'axios';
/**
 * Multiple classes
 * @param {...string} classes All desired StyleSheet classes
 * @summary A helper for conveniently add multiple StyleSheet classes
 * @author Arnaud LITAABA
 */
import { END_POINT, INCREMENT, LEFT, NOT_SUPPORTED, SEPARATOR } from "./constant";
import { findCollections, firebase } from "../services/firebase/firebase";

export const defaultErrorMessage = {
    email: "Entrez une addresse email valide",
    password: "Entrez au moins 8 caractères",
    oldPassword: "Entrez au moins 8 caractères",
    confirmPassword: "Doit correspondre au mot de passe",
    fullName: "Entrez au moins 3 caractères",
    phoneNumber: "Entrez un numéro valide",
    username: "Entrez en minuscule au moins 3 caractères sans espace et caractères spéciaux",
    storeUsername: "Entrez en minuscule au moins 3 caractères sans espace et caractères spéciaux",
}


export const sortString = (a, b, target) => {
    if (a[target] < b[target]) {
        return -1;
    }
    if (a[target] > b[target]) {
        return 1;
    }
    return 0;
}

export const getGps = (successCallback, errorCallback) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
        errorCallback(NOT_SUPPORTED)
    }
}

/**
 *
 * @param firstFormIsValidValue {boolean} first value of formIsValid
 * @param dataToLoop {Object} the data to loop
 */
export const commonFormIsValid = (firstFormIsValidValue = true, dataToLoop) => {
    let formIsValid = firstFormIsValidValue;
    for (const dataKey in dataToLoop) {
        if (dataToLoop.hasOwnProperty(dataKey)) {
            formIsValid = formIsValid && dataToLoop[dataKey].valid;
        }
    }
    return formIsValid;
}

/*
export const clearSWCache = (cacheName) => {
    if ('caches' in window) {
        return caches.delete(cacheName);
    }
}
 */

export const copyText = (text) => {
    const listener = function (ev) {
        ev.preventDefault();
        ev.clipboardData.setData('text/plain', text);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
}

const userFields = {
    personal: [
        "fullName","username", "personalAddress", "country", "city", "phoneNumber"
    ],
    store: [
        "storeUsername", "storeAddress", "storeCountry", "storeDescription", "storePhoneNumber"
    ]
}

export const userIsActive = (loggedInUser) => {
    let active = true;
    userFields.personal.forEach(v => {
        active = active && loggedInUser[v] && loggedInUser[v] !== "";
    })
    userFields.store.forEach(v => {
        active = active && loggedInUser[v] && loggedInUser[v] !== "";
    })
    active = active && loggedInUser.active;
    return active;
}

export const commonPasswordTask = (passwordsData, target, value) => {
    const validation = {
        ...passwordsData[target].validation,
        match: {
            value: target === "password" ? passwordsData["confirmPassword"].value : target === "confirmPassword" ? passwordsData["password"].value : value
        }
    };

    const valid = validate(value, validation);

    const newData = {
        ...passwordsData,
        [target]: {
            ...passwordsData[target],
            value: value,
            touched: true,
            ...validation,
            valid,
            errorMessage: defaultErrorMessage[target]
        }
    }

    if (target === "password") {
        newData.confirmPassword.valid = valid;
        newData.confirmPassword.touched = true;
        newData.confirmPassword.errorMessage = defaultErrorMessage.confirmPassword;
        newData.password.errorMessage = value.length >= 8 ? "Doit correspondre à la confirmation" : defaultErrorMessage.password;
    }
    if (target === "confirmPassword") {
        newData.password.valid = valid;
        newData.password.touched = true;
    }
    return newData;
}

export const getKeywords = (value) => {
    let keywords = [];
    let trimName = value?.trim();
    for (let i = 0; i < trimName.length; i++) {
        for (let j = trimName.length; j > 0; j--) {
            if ((j) < trimName.length) {
                keywords.push(trimName.slice(i, j).toLowerCase())
            } else {
                keywords.push(trimName.slice(i, trimName.length).toLowerCase())
            }
        }

    }
    keywords = [...[...new Set([...keywords])].filter(v => v !== "")];
    keywords.push(value)
    keywords.push(value.toUpperCase())
    keywords.push(value.toLowerCase());
    return keywords
}

export const multipleClasses = (...classes) => classes.join(SEPARATOR);

/*export const cleanUp = () => {
    // const salifId = "UUwI5c3MbSe49Vl03lOeRqet2ve2"
    // const dieuDonneId = "Z2LF65VUNTNiwDIcHL9WRP6vaAo1"

    const id = "4zCGLszqHQZVE3YlVUt53OFBUri1"


    findCollections("products").where("sellerId", "==", id).get().then(docs => {

        docs.forEach(doc => {
            findCollections("products").doc(doc.id).delete().then(_ => {
                console.log(_);
                console.log("deleted ", doc.id);
            })
        })
    })

    findCollections("orders").where("sellerId", "array-contains", id).get().then(docs => {

        docs.forEach(doc => {
            findCollections("orders").doc(doc.id).delete().then(_ => {
                console.log(_);
                console.log("deleted ", doc.id);
            })
        })
    })

    findCollections("account_settings").doc(id).delete().then(_ => {
        console.log(_);
        console.log("deleted ");
    })

    findCollections("users").doc(id).delete().then(_ => {
        console.log(_);
        console.log("deleted ");
    })

    findCollections("products").doc("YIsyduwhgXPHsBAhlNEU").delete().then(_ => {
        console.log(_);
        console.log("deleted ");
    })
}*/

/**
 * Sum cart product prices
 * @summary A helper for conveniently sum cart values
 * @author Arnaud LITAABA
 */
export const sumCart = (first, next) => {
    const {data: firstData} = first;
    const {data: nextData} = next;
    if (firstData) return firstData + nextData;
    return first + nextData;
}

/**
 * Make index
 * @param {...Any || string || number} values All values for making unique index
 * @summary A helper for conveniently add unique key to component
 * @author Arnaud LITAABA
 */
export const makeIndex = (...values) => values.join("-|-");

/**
 * Is mobile
 * @summary A helper for mobile browsing detection
 * @author Arnaud LITAABA
 */
export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


/**
 * Over X value, here ten
 * @param {Number} value A desired max value before add +
 * @summary A helper for conveniently add data to badge
 * @author Arnaud LITAABA
 */
export const overTen = (value) => value > 9 ? "9+" : value;

/**
 * UpperFirst
 * @param {String} value The context trigger values function
 * @summary A helper for uppercase the first character of a string
 * @author Arnaud LITAABA
 */
export const upperFist = (value) => value ? value[0].toUpperCase() + value.slice(1) : value


export const toArray = (value) => {
    const finalArray = [];
    for (const valueKey in value) {
        if (value.hasOwnProperty(valueKey)) {
            finalArray.push({
                id: valueKey,
                data: value[valueKey]
            })
        }
    }
    return finalArray;
}

/**
 * Move file from one direction to another
 * @description The move files function is to move to the next or previous file
 * @param {Array} files The targeted files
 * @param {String} direction The direction to move
 * @author Arnaud LITAABA
 */
export const moveFile = (files, direction) => {
    const oldFiles = [...files];
    if (direction === LEFT) {

        // pop() give us the last value of our array
        const last = files.pop();

        // unshift() add the last value of our array in the first position
        files.unshift(last);

        // Obviously return the files
        return files
    }

    // shift() give us the first value of our array
    const first = oldFiles.shift();

    /* unshift() add the first value of our array in the first position, so
    * we need to reverse() the array before
    */
    oldFiles.reverse().unshift(first);

    // Obviously return the files
    return oldFiles.reverse()
}

// let total = 3089;

export const calculateRate = (value) => {

    const mo = value / 5;

    switch (true) {
        case value.toString().length === 7:
            return Math.round(mo / 200000);
        case value.toString().length === 6:
            return Math.round(mo / 20000);
        case value.toString().length === 5:
            return Math.round(mo / 2000);
        case value.toString().length === 4:
            return Math.round(mo / 200);
        case value.toString().length === 3:
            return Math.round(mo / 20);
        case value.toString().length === 2:
            return Math.round(mo / 2);
        default:
            return Math.round(mo);
    }
}

const validateEmail = (email) => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
};

export const infoIsFilled = (user) => {

    let filledStoreInfo = true;
    userFields.store.forEach(v => {
        filledStoreInfo = filledStoreInfo && typeof user[v] !== 'undefined' && user[v] !== "";
    })
    let filledPersonalInfo = true;
    userFields.personal.forEach(v => {
        filledPersonalInfo = filledPersonalInfo && typeof user[v] !== 'undefined' && user[v] !== "";
    })
    return {
        Boutique: filledStoreInfo,
        Compte: filledPersonalInfo
    };
}

export const validate = (value, rules) => {
    let isValid = true;
    if (rules.required) {
        isValid = value?.trim() !== '' && isValid;
    }
    if (rules.minLength) {
        isValid = value?.length >= rules.minLength && isValid;
    }
    if (rules.min) {
        isValid = Number(value) >= Number(rules.min) && isValid;
    }
    if (rules.max) {
        isValid = Number(value) <= Number(rules.max) && isValid;
    }
    if (rules.maxLength) {
        isValid = value?.length <= rules.maxLength && isValid;
    }
    if (rules.regex) {
        isValid = rules.regex.test(value) && isValid;
    }
    if (rules.isEmail) {
        isValid = validateEmail(value) && isValid;
    }
    if (rules.isPhoneNumber) {
        isValid = Number(value) && isValid;
    }
    if (rules.match) {
        isValid = value === rules.match.value && isValid;
    }

    return isValid;
}

export const uuid = () => {
    return 'xxxxxxxxxxxxxxx4xxxxxyxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
        return v.toString(16);
    });
}

export const getLocalShoppingCartDb = () => {
    return JSON.parse(sessionStorage.getItem("SC-DB") || "{}")
}

export const updateLocalShoppingCartDb = (finalDb) => {
    sessionStorage.setItem("SC-DB", JSON.stringify(finalDb))
}

export const compressImage = async (imageFile, onError) => {
    try {
        const base64 = await getBase64(imageFile);
        const { base64: compressedBase64 } = (await (axios.post(END_POINT.COMPRESS_IMAGE, { base64 }))).data;
        const blobResult = (await fetch(compressedBase64)).blob();
        const response = {
            base64: compressedBase64
        }
        await blobResult.then(r => {
            response["blob"] = r;
        })
        return response
    } catch (_) {
        onError(_);
        // console.log(error);
    }
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (file) {
            reader?.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    })
}

export const cleanProductName = (name) => name.replace(/[',;+"@() ]/g, "");

const cachedBlob = {};

export const retrieveBlob = (url, {updatedAt, originalImages}, position, callBack) => {

    if (cachedBlob[updatedAt]) {
        callBack(cachedBlob[updatedAt][position])
        return
    }

    fetch(url).then(res => res.blob()).then(blob => {
        cachedBlob[updatedAt] = cachedBlob[updatedAt] ?? [];
        cachedBlob[updatedAt][position] = {
            blob,
            originalBlob: null
        };
        if (typeof originalImages !== "undefined") {
            fetch(originalImages[position]).then(res => res.blob()).then(originalBlob => {
                cachedBlob[updatedAt][position] = {
                    ...cachedBlob[updatedAt][position],
                    originalBlob
                };
                callBack({blob, originalBlob});
            })
            return
        }
        callBack({blob, originalBlob: null})
    })
}

const cachedQueries = {};

export const queriesCaches = (id) => {
    return {
        get: () => (new Promise((resolve, reject) => {
            const result = cachedQueries[id];
            result && resolve(result)
            !result && reject(null);
        })),
        set: (data) => (new Promise(resolve => {
            cachedQueries[id] = data;
            resolve(true);
        }))
    }
}

export const defineBg = {
    completed: "#1BD895",
    canceled: "#F18084",
    processing: "#FEAF01",
}

export const defineBgText = {
    completed: "#088a5d",
    canceled: "#a10f15",
    processing: "#b07e0d",
}

export const statusText = {
    completed: "Completée",
    canceled: "Annulée",
    processing: "En cours",
}

export const typeText = {
    seller: "Vendeur",
    admin: "Admin",
}

export const sendTopkiferSms = async (smsData) => {
    const {result} = (await (axios.post(END_POINT.SMS, { smsData }))).data;
    return result
}

export const confirmCode = async (codeData) => (await (axios.post(END_POINT.CONFIRM_CODE,codeData))).data;

export const updateTrackers = (target, mode = INCREMENT) => {
    return new Promise(resolve => {
        findCollections("trackers").doc("users").update({
            [target]: firebase.firestore.FieldValue.increment(mode === INCREMENT ? 1 : -1)
        }).then(_ => resolve(true))
    })
}

export const updateSellersTrackers = (id, mode = INCREMENT) => {
    return new Promise(resolve => {
        findCollections("sellers_tracking").doc(id).update({
            totalProduct: firebase.firestore.FieldValue.increment(mode === INCREMENT ? 1 : -1)
        }).then(_ => resolve(true)).catch(er => {
            if (er.message.includes("No document to update")) {
                findCollections("sellers_tracking").doc(id).set({
                    totalProduct: mode === INCREMENT ? 1 : 0
                }).then(_ => resolve(true)) 
            }
        })
    })
}
