import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyD5ibMcihMhRm_fX9-yMViVi8LeyNxKEFs",
    authDomain: "topkiffer-ecom-site.firebaseapp.com",
    databaseURL: "https://topkiffer-ecom-site.firebaseio.com",
    projectId: "topkiffer-ecom-site",
    storageBucket: "topkiffer-ecom-site.appspot.com",
    messagingSenderId: "587281390466",
    appId: "1:587281390466:web:459502cdaafb84d9ea4d9b",
    measurementId: "G-9BRKYD47D1"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    //  firebase.analytics();
}

let firebaseAuth = firebase.auth();
let firebaseDatabase = firebase.firestore();
let firebaseStorage = firebase.storage();

/*if (window.location.hostname.includes("localhost") || window.location.hostname.includes("192.168.1.69")) {
    const host = "192.168.1.69"
    firebaseAuth.useEmulator(`http://${host}:9099`);
    firebaseDatabase.useEmulator(host, 8080);
    firebaseStorage.useEmulator(host, 9199);
}*/

const findCollections = (target) => firebaseDatabase.collection(target);

const uploadFileToStorage = (path, fileName, imageData) => firebaseStorage.ref(path + "/" + fileName).put(imageData);

export {firebase, firebaseAuth, firebaseDatabase, firebaseStorage, findCollections, uploadFileToStorage}
