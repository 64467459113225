import {REDUX_CONSTANTS} from "utilities/constant";

const {SHOW_NOTIFICATION, HIDE_NOTIFICATION, FROM_WELCOME} = REDUX_CONSTANTS;

const initialState = {
    content: "",
    showing: false,
    closing: false,
    duration: 5000,
    type: "default",
    creatingAccount: false
}

const notificationsReducer = (state = initialState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case SHOW_NOTIFICATION:
        case HIDE_NOTIFICATION:
        case FROM_WELCOME:
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}

export default notificationsReducer
