import {combineReducers} from "redux";
import loginReducer from "./auth/login/loginReducer";
import registerReducer from "./auth/register/registerReducer";
import sidebarWithReducer from "./sidebarWith/sidebarWithReducer";
import modalReducer from "./modal/modalReducer";
import appSettingsReducer from "./appSettings/appSettingsReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import maintenanceReducer from "./maintenance/maintenanceReducer";
import userSettingsReducer from "./userSettings/userSettingsReducer";

const rootReducers = combineReducers({
    loginState: loginReducer,
    registerState: registerReducer,
    sidebarState: sidebarWithReducer,
    modalState: modalReducer,
    appSettingState: appSettingsReducer,
    userSettingState: userSettingsReducer,
    notificationsState: notificationsReducer,
    maintenanceState: maintenanceReducer
})

export default rootReducers
