import React, {lazy, Suspense, useEffect} from "react";
// without module
import "./app.scss";
import {retrieveUserWithFirebase} from "redux/actions/auth/login/loginActions";
import {isMobile, sortString} from "utilities/utilities";
import {findCollections} from "./services/firebase/firebase";
import {setAppSettings} from "./redux/actions/appSettings/appSettingsActions";
import {SUCCESS} from "./utilities/constant";
// external
import {BrowserRouter as Router} from "react-router-dom";
import {connect} from "react-redux";
// with module
import Loading from "./views/pages/loading/Loading";
import CustomNotifications from "components/customNotifications/CustomNotifications";
import {setMaintenanceMode} from "./redux/actions/maintenance/maintenanceActions";

const AppRoute = lazy(() => import("./router/Router"));
const Header = lazy(() => import("components/header/Header"));
const Sidebar = lazy(() => import("components/sidebar/Sidebar"));

const App = ({loginState, ...rest}) => {

    const {retrieveUserWithFirebase, setAppSettings, setMaintenanceMode} = rest;

    useEffect(() => {

        if (loginState === null) {
            retrieveUserWithFirebase();
        }

    }, [loginState, retrieveUserWithFirebase]);

    useEffect(() => {
        findCollections("maintenance").doc("state").onSnapshot(doc => {
            setMaintenanceMode({
                state: doc.data().value
            })
        })
    }, [setMaintenanceMode])

    useEffect(() => {
        let settingsSub = () => void 0;
        let countriesData = [];
        let settings = findCollections("site_settings");
        let countries = findCollections("countries");
        const next = (countriesValues, idd) => {
            settings.onSnapshot(data => {
                    data.forEach(v => {
                        if (v.id !== "TRACKED_ORDER_ID") {
                            const values = [...countriesValues.sort((a, b) => sortString(a, b, "idd"))]
                            localStorage.setItem("countriesIddCache", JSON.stringify(values));
                            localStorage.setItem("defaultIdd", idd);
                            const {logo, whatsapp, inventoryLimit,stockLimit} = v.data();
                            setAppSettings({
                                number: whatsapp,
                                logo: logo.white.desktop,
                                countries: [...values],
                                idd,
                                inventoryLimit,
                                stockLimit
                            });

                        }
                    })
                }
            )
        }

        const cachedIddData = localStorage.getItem("countriesIddCache");
        const defaultIddData = localStorage.getItem("defaultIdd");

        if (cachedIddData) {
            next(JSON.parse(cachedIddData), defaultIddData ?? null);
            return
        }

        countries.get().then(res => {
            res.forEach(doc => {
                countriesData.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            fetch('https://api.ipregistry.co/?key=tryout')
                .then(function (response) {
                    return response.json();
                })
                .then(function (res) {
                    next(countriesData, "+" + res.location.country.calling_code);
                }).catch(_ => {
                next(countriesData, null);
            });

        })
        return settingsSub
    }, [setAppSettings, loginState]);

    if (loginState === null) {
        return <Loading/>
    }


    return <Suspense fallback={<Loading/>}>
        <Router>
            <CustomNotifications/>
            <div className="app">
                {loginState === SUCCESS && <Header cache/>}
                <div className="app-container">
                    {loginState === SUCCESS && <Sidebar cache/>}
                    {rest.sidebarWith === 0 ?
                        <div style={{width: "100%", marginTop: loginState === SUCCESS ? "8rem" : 0}}
                             className="appRouteSection">
                            <AppRoute/>
                        </div> : <div style={{width: isMobile ? "100%" : `calc(100% - ${rest.sidebarWith})`}}
                                      className="appRouteSection">
                            <AppRoute/>
                        </div>}
                </div>
            </div>
        </Router>
    </Suspense>
}

/**
 * connect the menu Component to the whole store
 * Extract the function and the value of the whole state we need here
 * @param {Object} state the whole state managed by redux
 * @return {Object} all desired value from redux
 * @author Arnaud LITAABA
 */
const mapStateToProps = state => {
    return {
        loginState: state.loginState.state,
        sidebarWith: state.sidebarState.value
    }
}

/**
 * connect the app Component to the whole store
 * @description Extract the theme value
 * bind theme value to app props
 * @author Arnaud LITAABA
 */
export default connect(mapStateToProps, {
    retrieveUserWithFirebase, setAppSettings, setMaintenanceMode
})(App)

