import {REDUX_CONSTANTS} from "utilities/constant";

const initialLoginState = {
    logo: "",
    number: "",
    countries: [],
    idd: null,
    inventoryLimit: {}
}

const appSettingsReducer = (state = initialLoginState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case REDUX_CONSTANTS.SET_LOGO_URL:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default appSettingsReducer
