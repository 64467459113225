import React from "react";
import classes from "./loading.module.scss";
import Icon from "../../../components/icon/Icon";
import LoadingIcon from "../../../assets/icons/loading.svg"

const {rippleLoading, wrapper, child,text} = classes;

const Loading = () => {
    return <div className={rippleLoading}>
        <Icon size={"100px"} src={LoadingIcon}/>
        <div className={text}>Topkifer</div>
        <div className={wrapper}>
            <div className={child}/>
            <div className={child}/>
            <div className={child}/>
        </div>
    </div>
};

export default React.memo(Loading)
