import {
    APP_URL,
    FAILED,
    IS_LOGOUT,
    IS_NOT_LOGOUT,
    PROCESSING,
    REDUX_CONSTANTS,
    RESET_PASSWORD,
    RETRIEVE_FAILED,
    SUCCESS
} from "utilities/constant";
import {asynchronousDispatcher, synchronousDispatcher} from "../../rootActions";
import {findCollections, firebaseAuth} from "services/firebase/firebase";
import {firebase} from "../../../../services/firebase/firebase";

const {LOGIN, CHANGE_LOGIN_STATE, LOGOUT} = REDUX_CONSTANTS;

export const logInTheUser = (payload) => synchronousDispatcher(CHANGE_LOGIN_STATE, payload);
export const logOutTheUser = (loggedInUser, next) => {
    return dispatch => {
        firebaseAuth.signOut()
            .then(() => {
                asynchronousDispatcher(dispatch, LOGOUT, {
                    loggedInUser: {},
                    isLoggedIn: false,
                    state: IS_LOGOUT
                });
                setTimeout(() => {
                    next()
                }, 1000)
            })
            .catch((_) => {
                // console.log("can not sign out")
                asynchronousDispatcher(dispatch, LOGOUT, {
                    loggedInUser,
                    isLoggedIn: true,
                    state: IS_NOT_LOGOUT
                });
            });
    }
};

export const loginWithFirebase = (userData) => {
    const {user} = userData;
    return dispatch => {
        let isLoggedIn = false;

        asynchronousDispatcher(dispatch, CHANGE_LOGIN_STATE, PROCESSING);

        if (user) {
            isLoggedIn = true;
            let userQuery = findCollections("users").doc(user.uid);
            userQuery.get().then(authUser => {
                if (authUser.exists) {
                    asynchronousDispatcher(dispatch, CHANGE_LOGIN_STATE, SUCCESS);
                    asynchronousDispatcher(dispatch, LOGIN, {
                        user: {
                            id: user.uid,
                            ...authUser.data(),
                        },
                        isLoggedIn,
                    });

                    if (!authUser.data().type.includes("seller")) {
                        userQuery.update({
                            type: firebase.firestore.FieldValue.arrayUnion("seller"),
                            active: false
                        })
                    }

                } else {
                    asynchronousDispatcher(dispatch, CHANGE_LOGIN_STATE, FAILED);
                }
            });
        }
    }
}

export const retrieveUserWithFirebase = () => {
    return dispatch => {
        let isLoggedIn = true;
        firebaseAuth.onAuthStateChanged( user => {
            if (user) {
                findCollections("users").doc(user.uid).onSnapshot({
                    next: (authUser) => {
                        asynchronousDispatcher(dispatch, CHANGE_LOGIN_STATE, SUCCESS);
                        asynchronousDispatcher(dispatch, LOGIN, {
                            user: {
                                id: user.uid,
                                ...authUser.data(),
                            },
                            isLoggedIn,
                        });
                    }
                });
            } else {
                asynchronousDispatcher(dispatch, CHANGE_LOGIN_STATE, window.location.pathname === APP_URL.RESET ? RESET_PASSWORD : RETRIEVE_FAILED);
            }
        })

    }
}
