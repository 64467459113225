import {REDUX_CONSTANTS} from "utilities/constant";

const {SET_MAINTENANCE_MODE} = REDUX_CONSTANTS;

const initialState = {
    state: ""
}

const maintenanceReducer = (state = initialState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case SET_MAINTENANCE_MODE:
            return {
                ...state,
                ...payload,
            }
        default:
            return state
    }
}

export default maintenanceReducer
