import {REDUX_CONSTANTS} from "utilities/constant";

const initialState = {
    
}

const userSettingsReducer = (state = initialState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case REDUX_CONSTANTS.SET_USER_SETTINGS:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default userSettingsReducer
