import {REDUX_CONSTANTS} from "utilities/constant";

const modalState = {
    show: false,
    header: "",
    footer: null,
    state: ""

}

const modalReducer = (state = modalState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case REDUX_CONSTANTS.CHANGE_MODAL_STATE:
            return {
                ...state,
                ...payload
            }
        case REDUX_CONSTANTS.CHANGE_MODAL_ACTION:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default modalReducer