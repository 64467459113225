export const synchronousDispatcher = (type, payload) => {
    return {
        type: type,
        payload
    }
}

export const asynchronousDispatcher = (dispatcher, type, payload) => {
    return dispatcher({
        type: type,
        payload
    })
}
