export const SEPARATOR = " ";
export const SPLIT_KEY = "x7K 7l7m";
export const SPLIT_KEY_2 = "M 2m";
export const defaultIconSize = "24px";
export const PROCESSING = "processing";
export const NOT_SUPPORTED = "not supported";
export const MINUS_SIGN = "-";
export const SUM_SIGN = "+";
export const FAILED = "failed";
export const RETRIEVE_FAILED = "retrieve user failed";
export const RESET_PASSWORD = "reset password";
export const DUPLICATE_EMAIL = "duplicate email";
export const WRONG_PASSWORD = "auth/wrong-password";
export const USER_NOT_FOUND = "auth/user-not-found";
export const TOO_MANY_REQUESTS = "auth/too-many-requests";
export const SUCCESS = "success";
export const COMPLETED = "completed";
export const IS_LOGOUT = "is logged out";
export const IS_NOT_LOGOUT = "is not logged out";
export const LOGIN = "auth";
export const REGISTER = "register";
export const RIGHT = "right";
export const LEFT = "left";
export const BLACK = "black";
export const TOP = "top";
export const INSIDE_NAVIGATION = "INSIDE_NAVIGATION";
export const NOT_SET = "Non renseigné";
export const HIDING_PASSWORD = "********";
export const REDUCED_SIDEBAR_WITH = "4%";
export const FULL_SIDEBAR_WITH = "15%";
export const PRODUCT_NAME_LIMIT = 12;
export const ACTIVATION_MESSAGE = "Bonjour%2C%20Topkifer%2C%20J%27ai%20bien%20rempli%20les%20informations%20de%20mon%20compte%20et%20de%20ma%20boutique.%20J%27aimerais%20activer%20mon%20compte%20pour%20pouvoir%20ajouter%20des%20produits.%20Merci";
export const CONTACT_MESSAGE = "Bonjour%2C%20Topkifer.";
export const INCREMENT = "increment";
export const DECREMENT = "decrement";
export const SHOPPER_SITE = "https://topkifer.com";
export const DONE = "done";

export const SUBSCRIPTION = {
    BASIC: "basic",
    PREMIUM: "premium",
    ENTERPRISE: "enterprise"
}


export const CATEGORIES_CONNSTANTS = {
    FASHION: "fashion",
    ELECTRONICS: "electronics"
}

const getURl = (path) => {
    return `https://${path}-633wahy4za-uc.a.run.app`
}

export const APP_END_POINT = {
    DEV: {
        CONFIRM_CODE: "http://localhost:5001/topkiffer-ecom-site/us-central1/confirmCode",
        PLUS_CODE: "http://localhost:5001/topkiffer-ecom-site/us-central1/getPlusCode",
        COMPRESS_IMAGE: "http://localhost:5001/topkiffer-ecom-site/us-central1/compressImage",
        SMS: "http://localhost:5001/topkiffer-ecom-site/us-central1/sendSms",
        CHANGE_AUTH_NUMBER: "http://localhost:5001/topkiffer-ecom-site/us-central1/updateAuthNumber",
    },
    PROD: {
        CONFIRM_CODE: getURl('confirmcode'),
        COMPRESS_IMAGE: getURl('compressimage'),
        SMS: getURl('sendsms'),
        PLUS_CODE: getURl('getpluscode'),
        CHANGE_AUTH_NUMBER: getURl('updateauthnumber'),
    }
};

export const END_POINT = APP_END_POINT.PROD;

export const APP_URL = {
    BASE: "/",
    HOME: "/home",
    LOGIN: "/auth",
    RESET: "/reset",
    LOGOUT: "/logout",
    PRODUCT: "/product",
    PRODUCTS: "/products",
    // REVIEWS: "/reviews",
    SETTINGS: "/settings",
    ACCOUNT: "/account",
    SEARCH: "/search",
    SEARCH_CAT: "/searchCat",
    COMING_SOON: "/coming-soon",
    ORDERS: "/orders",
    STORES: "/stores",
    NOT_FOUND: "*",
};

export const REDUX_CONSTANTS = {
    LOGIN: "auth",
    LOGOUT: "logout",
    REGISTER: "register",
    CHANGE_THEME: "change theme",
    CHANGE_LANGUAGE: "change language",
    ADD_TO_CART: "add to cart",
    UPDATE_CART: "update cart",
    CHANGE_CART: "change cart",
    CHANGE_LOGIN_STATE: "change login state",
    REGISTERING_STATE: "change register state",
    CHANGE_SIDE_BAR_WITH: "change sidebar with",
    SET_LOGO_URL: "set appSettings url",
    SET_USER_SETTINGS: "set userSettingss url",
    CHANGE_SIDE_BAR_STATE: "change sidebar state",
    CHANGE_MODAL_STATE: "change modal state",
    CHANGE_MODAL_ACTION: "change modal actions",
    SHOW_NOTIFICATION: "show notifications",
    HIDE_NOTIFICATION: "hide notifications",
    FROM_WELCOME: "from welcome page",
    SET_MAINTENANCE_MODE: "set maintenance mode",
}






