import {REDUX_CONSTANTS} from "utilities/constant";

const {LOGIN, ADD_TO_CART, CHANGE_LOGIN_STATE, LOGOUT} = REDUX_CONSTANTS;

const initialLoginState = {
    loggedInUser: {},
    state: null,
    isLoggedIn: false
}

const loginReducer = (state = initialLoginState, actions) => {
    const {type, payload} = actions;
    switch (type) {
        case LOGIN:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    ...payload.user
                },
                isLoggedIn: payload.isLoggedIn
            }
        case LOGOUT:
            return !payload.isLoggedIn ?{
                loggedInUser: {
                    ...payload.loggedInUser
                },
                state: payload.state,
                isLoggedIn: payload.isLoggedIn
            }: {
                ...state,
                ...payload
            }
        case ADD_TO_CART:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    cartTotal: payload
                }
            }
        case CHANGE_LOGIN_STATE: {
            return {
                ...state,
                state: payload
            }
        }
        default:
            return state
    }
}

export default loginReducer