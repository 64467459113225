import React, {useEffect} from "react";
import whiteClasses from "./white.module.scss";
import {connect} from "react-redux";
import {multipleClasses} from "../../utilities/utilities";
import {hideNotifications} from "../../redux/actions/notifications/notificationsActions";

let i = 0;

const notificationsStyles = {
    "default": {},
    danger: {
        color: "#f7f7f7",
        backgroundColor: "#CA0B00"
    },
    warning: {
        color: "#242424",
        backgroundColor: "#ffcc00"
    },
    success: {
        color: "#242424",
        backgroundColor: "#4BB543"
    },
    info: {
        color: "#242424",
        backgroundColor: "#17a2b8"
    }

}

const CustomNotifications = ({ notificationsState, hideNotifications}) => {

    const {showing, content, duration, closing, type} = notificationsState;
    
    const {notifications, notificationsAnimate, notificationsReverse} = whiteClasses;

    useEffect(() => {
        if (showing) {
            clearTimeout(i);
            i = setTimeout(() => {
                hideNotifications({showing: false, closing: false});
            }, duration)
        }
    }, [hideNotifications, duration, notificationsState, showing])

    return showing && <div style={{...notificationsStyles[type]}}
                           className={multipleClasses(notifications, !closing ? notificationsAnimate : notificationsReverse)}>
        {content + " !"}
    </div>
}

const mapStateToProps = state => {
    return {
        notificationsState: state.notificationsState,
    }
}

export default connect(mapStateToProps, {hideNotifications})(React.memo(CustomNotifications))
